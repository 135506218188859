<template>
  <InstructorList></InstructorList>
</template>

<script>
import InstructorList from "../components/InstructorList";
export default {
  components: { InstructorList },
};
</script>

<style></style>
