<template>
  <div>
    <ModuleLocked
      :title="$t('upgradeAccount.instructorLimitTitle')"
      :description="$t('upgradeAccount.instructorLimitDescription')"
      v-if="CHECK_LIMIT_OF_MODULE()"
    />
    <section v-else-if="getActiveUser.roleId === 1" class="vid-title-sec">
      <div class="bg-light card">
        {{ $t("instructorListInfo") }}
      </div>
      <form @submit="SEND_USER">
        <div class="vid-title my-3">
          <h2 class="title-hd">{{ $t("instructorNameAndLastname") }}</h2>
          <div class="form_field">
            <input
              type="text"
              required
              v-model="form.fullname"
              maxlength="100"
              :placeholder="$t('addHere')"
            />
          </div>
        </div>
        <div class="vid-title my-3">
          <h2 class="title-hd">{{ $t("instructorEmail") }}</h2>
          <div class="form_field">
            <input
              type="email"
              required
              v-model="form.email"
              maxlength="100"
              :placeholder="$t('addHere')"
            />
          </div>
        </div>
        <div class="vid-title my-3">
          <h2 class="title-hd">{{ $t("instructorPhone") }}</h2>
          <div class="form_field">
            <input
              type="tel"
              required
              v-model="form.phone"
              pattern="\+[0-9]{0,20}$"
              maxlength="20"
              :title="$t('patternPhone')"
              :placeholder="$t('addHereN', { n: 20 })"
            />
          </div>
        </div>
        <div class="vid-title my-3">
          <h2 class="title-hd">{{ $t("instructorPassword") }}</h2>
          <div class="form_field">
            <input
              type="password"
              required
              v-model="form.password"
              minlength="3"
              maxlength="100"
              :placeholder="$t('addHere')"
            />
          </div>
        </div>
        <div class="text-right mt-4">
          <button @click="$emit('close')" class="btn mx-2 btn-pill">
            {{ $t("close") }}
          </button>
          <button
            :disabled="isLoading"
            type="submit"
            class="btn mx-2 btn-pill donate"
          >
            <loading v-if="isLoading"></loading>
            <span v-else>{{ $t("save") }}</span>
          </button>
        </div>
      </form>
    </section>
    <NoAuth v-else />
  </div>
</template>

<script>
import Loading from "../Shared/Loading.vue";
import NoAuth from "../NoAuth/index.vue";
import Mail from "../../mixins/Mail";
import CountryCodes from "../../plugins/data/CountryCodes.json";
import Modules from "../../mixins/Modules.js";
import ModuleLocked from "../Packages/ModuleLocked.vue";
export default {
  mixins: [Mail, Modules],
  components: { Loading, NoAuth, ModuleLocked },
  props: {
    page: {
      default: 1,
    },
    count: {
      default: 0,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        fullname: "",
        email: "",
        password: "",
        phone: "",
        remoteId: 1,
        langCode: "de",
      },
    };
  },
  computed: {
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    CHECK_LIMIT_OF_MODULE() {
      const instructorLimit = Number(this.instructorModule.value) || 0;
      if (instructorLimit == 0) return false;
      return instructorLimit <= this.count;
    },
    SEND_EMAIL_TO_ADDED_USER(form) {
      this.$store.commit("SET_MAIL_DRAWER", {
        status: true,
        form: {
          to: form.email,
          bcc: "",
          subject: this.$t("sendEmailToAddedUserSubject", {
            fullname: form.fullname,
            company: this.getSettings.title,
          }),
          content: this.$t("sendEmailToAddedUserContent", {
            ...this.form,
            website: this.getSettings.panelWebsite,
            company: this.getSettings.title,
          }),
          files: [],
        },
      });
    },
    CHECK_LANG_CODE() {
      const response = CountryCodes.find((country) =>
        this.form.phone.startsWith(country.dial_code)
      ) || { code: "tr" };
      return response.code.toLowerCase();
    },
    async SEND_USER(e) {
      this.isLoading = true;
      this.$store.commit("RESET_CUSTOMER_LIST", false);
      e.preventDefault();
      this.form.langCode = this.CHECK_LANG_CODE();
      const fd = new FormData();
      fd.append("Fullname", this.form.fullname);
      fd.append("Email", this.form.email);
      fd.append("Password", this.form.password);
      fd.append("Phone", this.form.phone);
      fd.append("RemoteId", 1);
      fd.append("LangCode", this.CHECK_LANG_CODE());
      const response = await this.$store.dispatch("SEND_NEW_INSTRUCTOR", {
        fd,
        form: { ...this.form, page: this.page },
      });
      if (response) {
        this.SEND_EMAIL_TO_ADDED_USER(this.form);
        this.$emit("refresh");
        this.$emit("close");
      }
      this.isLoading = false;
    },
  },
};
</script>
