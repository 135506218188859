<template>
  <div class="container">
    <h2 v-if="!selectable">
      {{ $t("instructors") }}
      <span class="text-muted">{{
        $store.state.Customers.instructorCount
      }}</span>
    </h2>
    <h5 v-if="!selectable" class="text-muted mt-2 mb-4">
      {{ $t("followYourInstructors") }}
    </h5>
    <div class="text-right" v-if="getActiveUser.roleId === 1">
      <button
        @click="showInsertInstructor = true"
        class="btn btn-pill subscribe mx-2"
      >
        + {{ $t("addInstructor") }}
      </button>
    </div>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <div
      v-if="selectedAllItemWithoutPage || checkedList.length !== 0"
      class="my-4 table-header text-center align-items-center d-flex w-100 justify-content-center"
    >
      <span
        @click="GET_ALL_USER_EMAIL"
        v-if="selectedAllItemWithoutPage"
        class="tel-link"
      >
        ({{ count }}) {{ $t(selectable ? "saveUsers" : "sendMail") }}
      </span>
      <span
        @click="SAVE_USER_OF_CHECKED_LIST"
        v-else-if="checkedList.length !== 0"
        class="tel-link"
      >
        ({{ checkedList.length }})
        {{ $t(selectable ? "saveUsers" : "sendMail") }}
      </span>
    </div>
    <Tables
      checkable
      :headers="headers"
      :count="count"
      :items="getCustomers"
      :check-value="checkedList"
      :loading="getCustomersAreLoading"
      :searchPlaceholder="$t('searchForNamePhoneAndEmail')"
      @change:selectAllWithoutPage="SET_SELECT_ALL_WITHOUT_PAGE"
      @change:checkedList="(e) => (checkedList = e)"
      @change:search="SET_SEARCH"
    >
      <template #fullname="{ item }">
        <div class="d-flex justify-content-between align-items-center">
          <router-link :to="`/user/${item.id}`">
            <strong>{{ item.fullname }}</strong>
          </router-link>
          <div>
            <span
              class="mr-2 badge"
              :class="{
                'badge-success': item.isActive === 1,
                'badge-danger': item.isActive === 0,
              }"
              >{{ item.isActive === 1 ? $t("active") : $t("closed") }}</span
            >
            <img
              :src="`https://flagicons.lipis.dev/flags/4x3/${
                item.langCode?.toLowerCase() || 'tr'
              }.svg`"
              class="float-right"
              :title="item.langCode?.toLowerCase() || 'tr'"
              width="30"
              :alt="item.langCode"
            />
          </div>
        </div>
        <div>
          <small>
            <a class="email-link" :href="`mailto:${item.email}`">{{
              item.email
            }}</a>
          </small>
        </div>
      </template>
      <template #createdAt="{ item }">
        <strong>{{ SET_DATE(item.createdAt) }}</strong></template
      >
      <template #phone="{ item }"
        ><a class="tel-link" :href="`tel:${item.phone}`">{{
          item.phone
        }}</a></template
      >
      <template
        #approval="{ item }"
        v-if="$store.state.User.settings.isFile === 1"
      >
        <img
          :src="$api.image(item.verifyFilePath)"
          alt=""
          width="60"
          height="20"
          @click="SET_CHANGE_APPROVAL_IMAGE(item.verifyFilePath)"
          style="cursor: pointer"
          v-if="item.verifyFilePath !== null"
        />
        <span v-else>yok</span>
      </template>
      <!-- :src="`/images/flags/${item.langCode?.toLowerCase() || 'tr'}.svg`" -->
      <template #options="{ item }">
        <router-link :to="`/user/${item.id}`"
          ><button class="btn btn-pill donate mx-1">
            {{ $t("view") }}
          </button></router-link
        >
      </template>
      <template #status="{ item }">
        <span
          class="badge badge-success"
          :class="{
            'badge-success': item.isVerify === 1,
            'badge-danger': item.isVerify === -1,
            'badge-warning': item.isVerify === 0,
          }"
        >
          {{
            item.isVerify === 1
              ? $t("approved")
              : item.isVerify === -1
              ? $t("notApproved")
              : item.isVerify === 0
              ? $t("pendingApproval")
              : ""
          }}
        </span>
      </template>
    </Tables>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <Modal hide-footer v-model="showInsertUser">
      <insert-user
        @refresh="GET_LIST"
        :page="page"
        @close="showInsertUser = false"
      ></insert-user>
    </Modal>
    <Modal hide-footer v-model="showInsertInstructor">
      <insert-instructor
        @refresh="GET_LIST"
        :page="page"
        :count="$store.state.Customers.instructorCount"
        @close="showInsertInstructor = false"
      ></insert-instructor>
    </Modal>
    <Modal
      hide-footer
      v-model="approvalImage"
      :value="approvalImage"
      :lg="true"
      :hideHeader="false"
      :title="$t('certificateApproval')"
    >
      <div class="d-flex justify-content-center align-items-center">
        <img
          :src="$api.image(approvalFilePath)"
          alt=""
          width="100%"
          height="auto"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import Tables from "../Tables/index.vue";
import { formatter } from "gurhan/helpers/Date";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";
import Modal from "../Shared/Modal/index.vue";
import InsertUser from "./InsertUser.vue";
import InsertInstructor from "./InsertInstructor.vue";

export default {
  components: {
    Tables,
    BoostrapPagination,
    Modal,
    InsertUser,
    InsertInstructor,
  },
  props: {
    selectable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      search: "",
      showInsertUser: false,
      showInsertInstructor: false,
      approvalImage: false,
      checkedList: [],
      selectedAllItemWithoutPage: false,
      approvalFilePath: "",
      page: 1,
      limit: 10,
      headers: [
        {
          col: 10,
          sm: 4,
          lg: 4,
          xl: 4,
          title: this.$t("name"),
          value: "fullname",
        },
        {
          col: 11,
          sm: 3,
          lg: 3,
          xl: 2,
          title: this.$t("phone"),
          value: "phone",
        },
        {
          col: 11,
          sm: 2,
          lg: 2,
          xl: 1,
          title: this.$t("document"),
          value: "approval",
        },
        {
          col: 11,
          sm: 2,
          lg: 3,
          xl: 4,
          title: this.$t("joinDate"),
          value: "createdAt",
        },
        {
          col: 11,
          sm: 1,
          lg: 3,
          xl: 1,
          title: this.$t("approval"),
          value: "status",
        },
      ],
    };
  },
  computed: {
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
    getCustomersAreLoading() {
      return this.$store.state.Customers.instructorssAreLoading;
    },
    getCustomers() {
      return this.$store.state.Customers.instructors.filter(
        (c) => c.page === this.page
      );
    },
    pages() {
      return Math.ceil(
        this.$store.state.Customers.instructorCount / this.limit
      );
    },
    count() {
      return this.$store.state.Customers.instructorCount;
    },
  },
  methods: {
    formatter,
    SET_CHECK_LIST(checkedList) {
      return checkedList
        .map((c) => c.email)
        .reduce(function (a, b) {
          if (a.indexOf(b) < 0) a.push(b);
          return a;
        }, [])
        .join(",");
    },
    // toplu bir şekilde userları ürünlere kayıt etme.
    SAVE_USER_TO_PRODUCT(userList) {
      this.$emit("saveUsertoProduct", userList);
    },
    SAVE_USER_OF_CHECKED_LIST() {
      if (this.selectable) return this.SAVE_USER_TO_PRODUCT(this.checkedList);
      this.$store.commit("SET_MAIL_DRAWER", {
        status: true,
        form: {
          to: "",
          bcc: this.SET_CHECK_LIST(this.checkedList),
          subject: ``,
          content: "",
          files: [],
        },
      });
    },
    async GET_ALL_USER_EMAIL() {
      this.$store.commit("SET_LOADING_MODAL", {
        loading: true,
        alertBox: {
          status: false,
          variant: "success",
          message: "",
        },
      });
      const response = await this.$api.get(
        `Users?roleId=3&page=1&searchKey=${this.search}&limit=${this.$store.state.Customers.count}`
      );

      this.$store.commit("SET_LOADING_MODAL", {
        loading: false,
        alertBox: {
          status: false,
          variant: "success",
          message: "",
        },
      });
      if (response.message === "OK") {
        this.checkedList = response.data.items;
        if (this.selectable) return this.SAVE_USER_TO_PRODUCT(this.checkedList);
        this.$store.commit("SET_MAIL_DRAWER", {
          status: true,
          form: {
            to: "",
            bcc: response.data.items
              .map((c) => c.email)
              .reduce(function (a, b) {
                if (a.indexOf(b) < 0) a.push(b);
                return a;
              }, [])
              .join(","),
            subject: ``,
            content: "",
            files: [],
          },
        });
      }
    },
    SET_SELECT_ALL_WITHOUT_PAGE(all) {
      this.selectedAllItemWithoutPage = all;
    },
    SET_PAGINATION(page, request = true) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page } });
      if (request) this.GET_LIST();
    },
    SET_DATE(d) {
      const date = new Date(d);
      return formatter(date, { timeZone: "Etc/GMT-3" });
    },
    SET_SEARCH(search) {
      this.SET_PAGINATION(1, false);
      this.search = search;
      this.GET_LIST();
    },
    GET_LIST() {
      this.$store.dispatch("GET_CUSTOMERS_INSTRUCTOR", {
        page: this.page,
        search: this.search,
        limit: 10,
      });
    },
    SET_CHANGE_APPROVAL_IMAGE(val) {
      this.approvalFilePath = val;
      this.approvalImage = true;
    },
  },
  mounted() {
    if (!isNaN(Number(this.$route.query.page)))
      this.page = Number(this.$route.query.page);
    this.GET_LIST();
  },
};
</script>

<style></style>
